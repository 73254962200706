import { Component, OnInit, Input } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { MessageService, AppBarTitleService, EntityData } from "common";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ContactDataFormComponent } from "../contact-data-form-component";
import { untilDestroyed } from "@ngneat/until-destroy";
import { EntityFacade } from "../../entity/domain/+state/entity.facade";

@Component({
  selector: "ifp-company-contact-data-form.",
  templateUrl: "./company-contact-data-form.component.html",
  styleUrls: ["./company-contact-data-form.component.scss"],
})
export class CompanyContactDataFormComponent
  extends ContactDataFormComponent
  implements OnInit
{
  @Input()
  currentCompanyData: EntityData;

  constructor(
    formBuilder: UntypedFormBuilder,
    route: ActivatedRoute,
    router: Router,
    messageService: MessageService,
    private entityFacade: EntityFacade,
    snackBar: MatSnackBar,
    appBarTitleService: AppBarTitleService
  ) {
    super(
      formBuilder,
      route,
      router,
      messageService,
      snackBar,
      appBarTitleService
    );
    this.formInit();
  }

  ngOnInit() {
    this.route.parent.data
      .pipe(untilDestroyed(this))
      .subscribe(this.dataInit.bind(this));

    this.appBarTitleService.title = "Profile";
  }

  private dataInit(data: any): void {
    if (!data) return;

    this.currentCompanyData = data.entity;

    this.form.patchValue({
      phone: this.currentCompanyData?.phone,
      address: {
        line1: this.currentCompanyData?.address?.line1,
        line2: this.currentCompanyData?.address?.line2,
        city: this.currentCompanyData?.address?.city,
        state: this.currentCompanyData?.address?.state,
        zip: this.currentCompanyData?.address?.zip,
      },
    });
  }

  private formInit(): void {
    this.form = this.formBuilder.group({
      phone: ["", this.phoneValidator],
      address: this.getAddressFormGroup(),
    });
  }

  submit() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    const data = {
      address: Object.assign({}, this.form.value.address),
      phone: this.form.value.phone,
    };
    const entityId = this.currentCompanyData?.id;

    this.submitSub = this.entityFacade
      .changeAddress(data, entityId)
      .subscribe(this.submitSuccess, this.submitError);
  }
}
