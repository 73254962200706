import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { ActivatedRoute } from "@angular/router";
import { MessageService, ProductCode } from "common";
import { AccountPaymentService } from "../account-payment.service";
import { AccountPayment } from "../account-payment.data";
import { LoanData } from "../../../loan/domain/models/loan.model";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "ifp-account-payment-table",
  templateUrl: "./account-payment-table.component.html",
  styleUrls: ["./account-payment-table.component.scss"],
})
export class AccountPaymentTableComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  dataSource = new MatTableDataSource<AccountPayment>();
  data: AccountPaymentTableComponentData;
  displayedColumns: string[] = [];
  hidePageSize: boolean;
  productCode = ProductCode;

  constructor(
    private accountPaymentService: AccountPaymentService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    breakpointObserver: BreakpointObserver
  ) {
    breakpointObserver.observe([Breakpoints.XSmall]).subscribe((result) => {
      if (result.matches) this.hidePageSize = true;
      else this.hidePageSize = false;
    });
  }

  get pageSize() {
    return this.accountPaymentService.pageSize;
  }

  ngOnInit() {
    this.route.data
      .pipe(untilDestroyed(this))
      .subscribe((res: AccountPaymentTableComponentData) => {
        this.data = res;
        this.setTableDetails(this.data);
        this.dataSource.data = this.data.accountPayment;
      });

    this.dataSource.paginator = this.paginator;
  }

  page(event: PageEvent) {
    this.accountPaymentService.pageSize = event.pageSize;
    this.accountPaymentService.getPayments(this.data.loan.id).subscribe(
      (result) => (this.data.accountPayment = result),
      (error) => this.messageService.error(error)
    );
  }

  public downloadCsv(): void {
    this.accountPaymentService.downloadCsv(this.data.loan.id);
  }

  private setTableDetails(data: AccountPaymentTableComponentData) {
    const baseColumns = [
      "type-icon",
      "date",
      "type",
      "amount",
      "principalAmount",
      "interestAmount",
      "balance",
      "interestBalance",
      "totalInterest",
      "totalPrincipal",
      "totalPayback",
    ];
    if (data?.loan?.productCode === ProductCode.Term) {
      this.displayedColumns = [...baseColumns, 'discount', 'payoff'];
    } else {
      this.displayedColumns = baseColumns;
    }
  }
}

export interface AccountPaymentTableComponentData {
  accountPayment: AccountPayment[];
  loan: LoanData;
}
