import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, ReplaySubject } from "rxjs";
import { AppSettings } from "../../app.settings";
import { FinancialInstitution, IntegrationEventData, IntegrationEventType } from "./bank-account.model";

declare global {
    interface Window {
        Plaid: any;
    }
}

@Injectable({
    providedIn: 'root'
})

export class PlaidService {

    handler: { open, exit, ready };

    private readonly baseUrl = `${this.settings.banking.url}`;

    onIntegrationEvent: ReplaySubject<IntegrationEventData>;
    obsIntegrationEvent: Observable<IntegrationEventData>;

    constructor(
        private router: Router,
        private http: HttpClient,
        private settings: AppSettings) {

        this.onIntegrationEvent = new ReplaySubject<IntegrationEventData>(1);
        this.obsIntegrationEvent = this.onIntegrationEvent.asObservable();
    }

    detectOAuthRedirect() {
        const receivedRedirectUri = window.location.href;
        if (!receivedRedirectUri.includes('oauth_state_id'))
            return;

        const json = localStorage.getItem('plaid_link_data');
        if (!json) {
            console.error('oauth_state_id param is present in url but plaid_link_data not found in localStorage');
            return;
        }
        const plaidLinkData = JSON.parse(json);

        this.router.navigateByUrl(plaidLinkData.redirectUri)
            .then(res => {
                if (res)
                    this.openHandler(plaidLinkData.token, plaidLinkData.entityId, receivedRedirectUri)
            });
    }

    open(entityId: number, institution?: FinancialInstitution) {
        const that = this;

        const createLinkTokenRequest = {
            entityId: entityId,
            institutionLoginId: institution?.institutionLoginId,
            institutionRoutingNumber: institution?.routingNumber,
            redirectUri: `${this.settings.rootSite.url}/bank-account-oauth-redirect`
        };

        that.http.post<string>(`${that.baseUrl}/api/plaid/link-token`, createLinkTokenRequest)
            .subscribe(token => {
                const plaidLinkData =
                {
                    token: token,
                    entityId: entityId,
                    redirectUri: window.location.href.replace(this.settings.rootSite.url, '')
                }
                const json = JSON.stringify(plaidLinkData);
                localStorage.setItem('plaid_link_data', json);
                that.openHandler(token, entityId);
            },
            error => {
                this.onIntegrationEvent.next({
                    type: IntegrationEventType.Error,
                    entityId: createLinkTokenRequest.entityId,
                    institutionId: '',
                    institutionName: '',
                    info: `plaid/link-token error: ${error}`
                })
            }
        );
    }

    private openHandler(token: string, entityId: number, receivedRedirectUri?: string) {
        const that = this;

        //https://plaid.com/docs/link/web/
        that.handler = window.Plaid.create({
            token: token,
            receivedRedirectUri: receivedRedirectUri,

            //The onSuccess callback is called when a user successfully links an Item.
            onSuccess: function (public_token, metadata) {
                localStorage.removeItem('plaid_link_data');
                const req = {
                    entityId: entityId,
                    publicToken: public_token,
                    institutionId: metadata.institution.institution_id,
                    institutionName: metadata.institution.name,
                    accounts: metadata.accounts
                };
                that.http.post<string>(`${that.baseUrl}/api/plaid/items`, req)
                    .subscribe(_itemId => {
                        const event: IntegrationEventData =
                        {
                            entityId: entityId,
                            institutionId: metadata.institution.institution_id,
                            institutionName: metadata.institution.name,
                            type: IntegrationEventType.Success,
                            info: null
                        };
                        that.onIntegrationEvent.next(event);
                    });
            },

            //The onExit callback is called when a user exits Link without successfully linking an Item,
            //or when an error occurs during Link initialization.
            onExit: function (error, metadata) {
                that.handler.exit();
                const event: IntegrationEventData =
                {
                    entityId: entityId,
                    institutionId: metadata?.institution?.institution_id,
                    institutionName: metadata?.institution?.name,
                    info: error?.error_message ?? `status: ${metadata.status}`,
                    type: IntegrationEventType.Error
                };

                if (error)
                    event.type = IntegrationEventType.Error;
                else
                    event.type = IntegrationEventType.Cancel;

                that.onIntegrationEvent.next(event);
            },

            //The onEvent callback is called at certain points in the Link flow.
            onEvent: function (eventName, _metadata) {
                console.log(eventName);
            }
        });

        that.handler.open();
    }
}
