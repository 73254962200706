<ifc-app-scroll-container [layout2List]="true">

  <div class="message-box info" *ngIf="!dataSource.data?.length" fxLayout="row" fxLayoutGap="12px"
    fxLayoutAlign="start center">
    <mat-icon class="icon">info</mat-icon>
    <span>No data found</span>
  </div>

  <table mat-table [dataSource]="dataSource" matSort *ngIf="dataSource.data?.length">
    <ng-container matColumnDef="type-icon">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let payment" class="table-cell">
        <div class="avatar medium mat-light-grey-bg">P</div>
      </td>
    </ng-container>

    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef> Date </th>
      <td mat-cell *matCellDef="let payment" class="table-cell"> {{payment.date | date: 'shortDate'}} </td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef> Type </th>
      <td mat-cell *matCellDef="let payment" class="table-cell"> {{payment.type}} </td>
    </ng-container>

    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef class="right-align"> Amount </th>
      <td mat-cell *matCellDef="let payment" class="table-cell right-align"> {{payment.amount | currency:'$'}} </td>
    </ng-container>

    <ng-container matColumnDef="principalAmount">
      <th mat-header-cell *matHeaderCellDef class="right-align"> Principal payment </th>
      <td mat-cell *matCellDef="let payment" class="table-cell right-align"> {{payment.principalAmount | currency:'$'}} </td>
    </ng-container>

    <ng-container matColumnDef="interestAmount">
      <th mat-header-cell *matHeaderCellDef class="right-align"> Interest payment </th>
      <td mat-cell *matCellDef="let payment" class="table-cell right-align"> {{payment.interestAmount | currency:'$'}} </td>
    </ng-container>

    <ng-container matColumnDef="balance">
      <th mat-header-cell *matHeaderCellDef class="right-align"> Closing Balance </th>
      <td mat-cell *matCellDef="let payment" class="table-cell right-align"> {{payment.balance | currency:'$'}} </td>
    </ng-container>

    <ng-container matColumnDef="interestBalance">
      <th mat-header-cell *matHeaderCellDef class="right-align"> Outstanding Interest </th>
      <td mat-cell *matCellDef="let payment" class="table-cell right-align"> {{payment.interestBalance | currency:'$'}} </td>
    </ng-container>

    <ng-container matColumnDef="totalInterest">
      <th mat-header-cell *matHeaderCellDef class="right-align">Total Interest Cost</th>
      <td mat-cell *matCellDef="let payment" class="table-cell right-align"> {{payment.totalInterest | currency:'$'}} </td>
    </ng-container>

    <ng-container matColumnDef="totalPrincipal">
      <th mat-header-cell *matHeaderCellDef class="right-align">Total Capital Repayment</th>
      <td mat-cell *matCellDef="let payment" class="table-cell right-align"> {{payment.totalPrincipal | currency:'$'}} </td>
    </ng-container>

    <ng-container matColumnDef="totalPayback">
      <th mat-header-cell *matHeaderCellDef class="right-align">Total Payback</th>
      <td mat-cell *matCellDef="let payment" class="table-cell right-align"> {{payment.totalInterest + payment.totalPrincipal | currency:'$'}} </td>
    </ng-container>

    <ng-container *ngIf="data?.loan?.productCode === productCode.Term" matColumnDef="discount">
      <th mat-header-cell *matHeaderCellDef class="right-align">Discount available</th>
      <td mat-cell *matCellDef="let payment" class="table-cell right-align"> {{payment.discountAvailable | currency:'$'}} </td>
    </ng-container>
    
    <ng-container *ngIf="data?.loan?.productCode === productCode.Term" matColumnDef="payoff">
      <th mat-header-cell *matHeaderCellDef class="right-align">Payoff amount</th>
      <td mat-cell *matCellDef="let payment" class="table-cell right-align"> {{payment.payoffAmount | currency:'$'}} </td>
    </ng-container>

    <ng-container matColumnDef="disclaimer">
      <td mat-footer-cell *matFooterCellDef colspan="11">
        *This is the Repayment Schedule as defined in the Term Loan Agreement dated as of {{data.loan?.activationDate | date:'shortDate'}} by and between Idea 247, Inc. and {{data.loan?.entityName}}.
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="table-header"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="table-row"></tr>

    <tr mat-footer-row *matFooterRowDef="['disclaimer']; sticky: true"></tr>
  </table>

</ifc-app-scroll-container>

<mat-paginator #paginator [hidePageSize]="hidePageSize" [pageSizeOptions]="[10, 20, 50, 100]" [pageSize]="pageSize" (page)="page($event)"
  [length]="data.accountPayment.length" showFirstLastButtons></mat-paginator>
